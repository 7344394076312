import Vue from 'vue'
import Vuex from 'vuex'
import { $api } from 'bh-mod'
Vue.use(Vuex)

export default {
	state: {
		getStarted: false,
		allSettings: {},
	},
	mutations: {
		SET_SETTINGS: (state, data) => {
			let temp = data
			if (data.userApp == null) {
				temp.userApp = {
					options: {
						seenIntro: false,
					}
				}
				state.allSettings = temp
			} else {
				state.allSettings = data
			}
		},
		SET_APPDATA(state, { users = [] }) {
			// TODO:
		},
		GET_STARTED: state => {
			state.getStarted = true
		},
	},
	actions: {
		SET_APPDATA: ({ commit, dispatch }, data) => {
			commit('SET_APPDATA', data)
			dispatch('FETCH_INSTANCE')
			dispatch('FETCH_USERS')
		},
		FETCH_INSTANCE: async ({ commit }) => {
			try {
				let { data } = await $api.get('/instances')
				data = data.filter(x => x.role?.name === 'bh_admin').map(x => {
					return {
						id: x.instance.id,
						name: x.instance.name
					}
				})
				commit('SET_INSTANCES', data)
			} catch (error) {
				console.error('An error occurred while fetching project list. Please try again.', error)
			}
		},

		FETCH_USERS: async ({ commit, getters }) => {
			commit('LOAD', true, { root: true })
			try {
				const { data } = await $api.get('/instances/:instance/users')

				let users = JSON.parse(JSON.stringify(data.users));
				users = users.filter(x => x.role?.id);
				users.forEach((x) => { x.role = x.role?.id; });

				commit("SET_USERS", users, { root: true });
				commit("SET_INSTANCE", data, { root: true });
				commit("UPDATE_ROLE_COUNTS", data, { root: true });

				let roles = data.roles

				if (!getters.isWorksheetActive) {
					roles = roles.filter(r => !['bh_agent', 'bh_agent_manager'].includes(r.name))
				}

				commit("SET_ROLES", roles, { root: true });
			} catch (error) {
				console.error('Error while fetching user list. Please try again!', error)
			}
			commit('LOAD', false, { root: true })
		}
	},
	getters: {
		isWorksheetActive(state, getters, rootState, rootGetters) {
			return rootGetters.hasWorksheetAppAccess && (state.allSettings?.instance?.options?.activeFeatures?.worksheet ?? true);
		}
	}
}
